import {
  ExclamationCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Button, Modal, Table, Tag, Tooltip } from "antd";
import React, { useState } from "react";
import moment from "moment";
import Progress from "react-progress-2";
import { apis, IMAGE_PATH } from "../../properties";
import { useRefreshTable } from "../../store";
import { useNavigate } from "react-router-dom";
import useAllProducts from "../../hooks/useAllProducts";
import { PostDataAuth } from "../../apiService/PostData";
import { toast } from "react-toast";

const { confirm } = Modal;

function EventDataTable() {
  const navigate = useNavigate();
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
    dataTable: true,
  });

  const allProducts = useAllProducts(filterData);

  const statusColor = (status) => {
    switch (status) {
      case "PENDING":
        return "orange";
      case "PUBLISHED":
        return "green";
      case "UNPUBLISHED":
        return "orange";
      case "DELETED":
        return "red";
      default:
        return "green";
    }
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image_url",
      key: "image_url",
      render: (text) => (
        <img
          src={IMAGE_PATH + "/" + text + "?tr=w-100,h-100"}
          alt="event"
          style={{
            width: "50px",
            height: "50px",
            objectFit: "cover",
            borderRadius: "50%",
          }}
        />
      ),
      width: 50,
    },
    {
      title: "Name",
      dataIndex: "name",
      width: 150,
    },
    {
      title: "Created Date",
      render: (record) =>
        record.created_at
          ? moment(record.created_at).format("YYYY-MM-DD h:mm:ss a")
          : "",
      width: 100,
    },
    {
      title: "Status",
      render: (record) => (
        <Tag className="tags" color={statusColor(record.status)}>
          {record.status}
        </Tag>
      ),
      fixed: "right",
      width: 50,
    },
    {
      title: "Action",
      render: (text, record) => (
        <>
          <Tooltip placement="bottom" title="Edit Product">
            <Button
              className="view_button"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => navigate(`/product-management/edit/${record.id}`)}
            />
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip placement="bottom" title="Unpublished Product">
            <Button
              className="delete_button"
              shape="circle"
              icon={<CloseOutlined />}
              onClick={() => changeStatusContent(record.id, "UNPUBLISHED")}
            />
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip placement="bottom" title="Published Product">
            <Button
              className="view_button"
              shape="circle"
              icon={<CheckOutlined />}
              onClick={() => changeStatusContent(record.id, "PUBLISHED")}
            />
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip placement="bottom" title="Delete Product">
            <Button
              className="delete_button"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => changeStatusContent(record.id, "DELETED")}
            />
          </Tooltip>
        </>
      ),
      fixed: "right",
      width: 100,
      align: "right",
    },
  ];

  const changeStatusContent = (id, status) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to ${status} this product?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        PostDataAuth(`${apis.PRODUCT_STATUS_CHANGE}/${id}`, {
          status,
        }).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            toast.success(responseJson.message);
            setRefreshTable(!refreshTable);
            Progress.hide();
          } else if (responseJson.status === "error") {
            toast.error(responseJson.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  return (
    <>
      <Table
        columns={columns}
        dataSource={allProducts.data}
        bordered
        pagination={{
          current: filterData.current_page,
          pageSize: filterData.pageSize,
          total: allProducts.total,
          onChange: (page, pageSize) => {
            setFilterData({
              ...filterData,
              page: page,
              pageSize: pageSize,
            });
          },
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
    </>
  );
}

export default EventDataTable;
