import { LeftOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import Progress from "react-progress-2";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PostDataAuth } from "../../apiService/PostData";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import useProductById from "../../hooks/useProductById";
import { apis } from "../../properties";
import { toast } from "react-toast";

function EditProduct() {
  const params = useParams();
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectImage, setSelectImage] = useState();

  useBreadCrumb("Product Edit", location.pathname, "", "add");
  const productById = useProductById(params.slug);

  useEffect(() => {
    form.setFieldsValue({
      name: productById.name,
    });
    // eslint-disable-next-line
  }, [productById]);

  const onFinishFailed = () => {
    toast.warn("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      console.log(values);
      Progress.show();
      const data = {
        ...values,
        id : productById.id,
        image_url: productById.image_url,
      };
      const body = new FormData();
      body.append("image_url", selectImage ? selectImage : "");
      body.append("data", JSON.stringify(data));

      PostDataAuth(apis.PRODUCT_UPDATE, body).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          toast.success(responseJson.message);
          form.resetFields();
          navigate(-1);
        } else if (responseJson.status === "error") {
          toast.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  const uploadFileChange = (event) => {
    setSelectImage(event.target.files[0]);
  };


  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="com_head">
          <LeftOutlined onClick={() => navigate(-1)} />
          <p className="common_header">Create new event</p>
        </div>

        <Form
          autoComplete="off"
          layout="vertical"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
        >
          <Form.Item
            label="Product name"
            name="name"
            rules={[
              {
                required: true,
                message: "Title cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Product name" />
          </Form.Item>

          <Form.Item name="image_url" label="Upload Main image">
            <Input
              type="file"
              size="large"
              placeholder="Upload Main image"
              onChange={(e) => uploadFileChange(e)}
              accept=".png, .jpg, .jpeg, .webp"
            />
          </Form.Item>

          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default EditProduct;
