// export const BASE_API_URL = 'http://localhost:8000/';
export const BASE_API_URL = 'https://api.marssurfaces.com/';
export const IMAGE_PATH = 'https://ik.imagekit.io/rzz0oh2tf'

export const apis = {
  ADMIN_LOGIN: `${BASE_API_URL}api/cms/v1/admin/login`,
  CKEDITOR_UPLOAD: `${BASE_API_URL}api/ckeditor-upload`,

  PRODUCT_CREATE: `${BASE_API_URL}api/cms/v1/product/create`,
  PRODUCT_UPDATE: `${BASE_API_URL}api/cms/v1/product/update`,
  PRODUCT_LIST: `${BASE_API_URL}api/cms/v1/product/list`,
  PRODUCT_STATUS_CHANGE: `${BASE_API_URL}api/cms/v1/product/status-change`,
  PRODUCT_BY_ID: `${BASE_API_URL}api/cms/v1/product`,

  PROJECT_CREATE: `${BASE_API_URL}api/cms/v1/project/create`,
  PROJECT_UPDATE: `${BASE_API_URL}api/cms/v1/project/update`,
  PROJECT_LIST: `${BASE_API_URL}api/cms/v1/project/list`,
  PROJECT_STATUS_CHANGE: `${BASE_API_URL}api/cms/v1/project/status-change`,
  PROJECT_BY_ID: `${BASE_API_URL}api/cms/v1/project`,


};

export const ckeditorConfig = {
  toolbar: [
    "heading",
    "|",
    "fontColor",
    "fontBackgroundColor",
    "|",
    "bold",
    "italic",
    "link",
    "bulletedList",
    "numberedList",
    "blockQuote",
    "|",
    "insertTable",
    "mediaEmbed",
    "|",
    "undo",
    "redo",
    "|",
    "imageUpload",
    "imageStyle:full",
    "imageStyle:side",
    "|",
    "code",
    "codeBlock",
    "|",
  ],
};
