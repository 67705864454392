import { Button } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProjectDataTable from "../components/Project/ProjectDataTable";
import useBreadCrumb from "../hooks/useBreadCrumb";


export default function ProjectManagement() {
  const navigate = useNavigate();
  const location = useLocation();
  useBreadCrumb("Project Management", location.pathname, "Project Management");

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          <Button
            className="primary__btn"
            onClick={() => navigate("/project-management/create")}
          >
            Add Project
          </Button>
        </div>
        <ProjectDataTable />
      </div>
    </div>
  );
}
