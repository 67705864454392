import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PostDataAuth } from "../../apiService/PostData";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import { apis, IMAGE_PATH } from "../../properties";
import Progress from "react-progress-2";
import { LeftOutlined } from "@ant-design/icons";
import { toast } from "react-toast";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function AddProject({ type }) {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [desData, setDesData] = useState();
  const [selectImage, setSelectImage] = useState();

  useBreadCrumb("Create project", location.pathname, "", "add");

  const onFinishFailed = () => {
    toast.warn("Please fill all the details");
  };

  const submitForm = () => {
    form.validateFields().then((values) => {
      Progress.show();
      const data = {
        ...values,
        description: desData,
      };
      const body = new FormData();
      body.append("image_url", selectImage ? selectImage : "");
      body.append("data", JSON.stringify(data));

      PostDataAuth(apis.PROJECT_CREATE, body).then((result) => {
        let responseJson = result;
        if (responseJson.status === "success") {
          Progress.hide();
          toast.success(responseJson.message);
          form.resetFields();
          navigate(-1);
        } else if (responseJson.status === "error") {
          toast.error(responseJson.message);
          Progress.hide();
        }
      });
    });
  };

  const uploadFileChange = (event) => {
    setSelectImage(event.target.files[0]);
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("file", file);
            body.append("location", "cdeditor-images");
            fetch(`${apis.CKEDITOR_UPLOAD}`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({ default: `${IMAGE_PATH}/${res.data.file_path}` });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="com_head">
          <LeftOutlined onClick={() => navigate(-1)} />
          <p className="common_header">Create new project</p>
        </div>

        <Form
          autoComplete="off"
          layout="vertical"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
        >
          <Form.Item
            label="Project title"
            name="title"
            rules={[
              {
                required: true,
                message: "Title cannot be empty!",
              },
            ]}
          >
            <Input type="text" size="large" placeholder="Project title" />
          </Form.Item>

          <Form.Item name="description" label="Project Description">
            <CKEditor
              config={{
                extraPlugins: [uploadPlugin],
                placeholder: "Project description",
              }}
              editor={ClassicEditor}
              data={desData}
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    "height",
                    "300px",
                    editor.editing.view.document.getRoot()
                  );
                  writer.setStyle(
                    "z-index",
                    "999999 !important",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDesData(data);
              }}
            />
          </Form.Item>

          <Form.Item
            name="image_url"
            label="Upload Main image"
            rules={[{ required: true, message: "Main image cannot be empty!" }]}
          >
            <Input
              type="file"
              size="large"
              placeholder="Upload Main image"
              onChange={(e) => uploadFileChange(e)}
              accept=".png, .jpg, .jpeg, .webp"
            />
          </Form.Item>

          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AddProject;
