import { Button } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProductDataTable from "../components/Products/ProductDataTable";
import useBreadCrumb from "../hooks/useBreadCrumb";


export default function ProductManagement() {
  const navigate = useNavigate();
  const location = useLocation();
  useBreadCrumb("Product Management", location.pathname, "Product Management");

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          <Button
            className="primary__btn"
            onClick={() => navigate("/product-management/create")}
          >
            Add Product
          </Button>
        </div>
        <ProductDataTable />
      </div>
    </div>
  );
}
