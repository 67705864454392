export const menuItems = [
  {
    name: "Dashboard",
    exact: true,
    to: "/dashboard",
    iconClassName: "bi bi-cast",
    route_key: "dashboard",
    privilege: ["SUPER_ADMIN"],
  },
  {
    name: "Product Management",
    exact: true,
    to: "/product-management",
    iconClassName: "bi bi-bag-check",
    route_key: "product-management",
    privilege: ["SUPER_ADMIN"],
  },
  {
    name: "Projects Management",
    exact: true,
    to: "/project-management",
    iconClassName: "bi bi-clipboard-data",
    route_key: "project-management",
    privilege: ["SUPER_ADMIN"],
  },
];
