import "react-progress-2/main.css";
import "./assets/css/Dashboard.css";
import "./assets/css/Login.css";
import "./assets/css/Header.css";
import "./assets/css/MenuSideBar.css";
import "./assets/css/ThemeChange.css";
import "./assets/css/Responsive.css";

import Progress from "react-progress-2";
import { ProtectedRoute } from "./auth/ProtectedRoute";
import NotFount from "./errorPage/404";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Layout from "./Layout";
import Login from "./pages/Login";
import ProductManagement from "./pages/ProductManagement";
import AddProduct from "./components/Products/AddProduct";
import EditProduct from "./components/Products/EditProduct";
import { ToastContainer } from "react-toast";
import ProjectManagement from "./pages/ProjectManagement";
import AddProject from "./components/Project/AddProject";
import EditProject from "./components/Project/EditProject";

function App() {
  return (
    <div className="App">
      <Progress.Component />
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        >
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/product-management" element={<ProductManagement />} />
          <Route path="/product-management/create" element={<AddProduct />} />
          <Route path="/product-management/edit/:slug" element={<EditProduct />} />

          <Route path="/project-management" element={<ProjectManagement />} />
          <Route path="/project-management/create" element={<AddProject />} />
          <Route path="/project-management/edit/:slug" element={<EditProject />} />

        </Route>

        <Route path="*" element={<NotFount />} />
      </Routes>
      <ToastContainer delay={3000} />
    </div>
  );
}

export default App;
