import React from "react";
import { useLocation } from "react-router-dom";
// import DashBoardBox from "../components/DashBoardWidgets/DashBoardBox";
import useBreadCrumb from "../hooks/useBreadCrumb";
import Hello from "../assets/images/hello.png";

function Dashboard() {
  const location = useLocation();
  useBreadCrumb("Dashboard", location.pathname, "Dashboard");

  return (
    <div className="dashboard">
      <div className="section_row_hello">
        <div className="hello">
          <h1>Hello Admin</h1>
          <p>
            Welcome to your dashboard. Here you can manage your website and
            everything related to it.
          </p>
        </div>
        <img src={Hello} alt="" />
      </div>
      {/* <div className="box_section">
        <DashBoardBox title="Students" count={0} icon={"bi bi-people"} />
        <DashBoardBoxß
          title="Universities"
          count={0}
          icon={"bi bi-ui-checks-grid"}
        />
        <DashBoardBox title="Courses" count={0} icon={"bi bi-handbag"} />
        <DashBoardBox title="Applications" count={0} icon={"bi bi-hdd-stack"} />
      </div> */}
    </div>
  );
}

export default Dashboard;
